<template>
  <div class="cmp-shopping-cart-overview utlz-reset utlz-row" :class="tableClasses">

    <div class="print hidden">
      <table class="page-header">
        <tr class="quote-hdr">
          <td>
            <h1 v-translation="{ code: 'title_print_quote_h1', type: 'title' }"></h1>
          </td>
          <td class="logo">
            <img :src="logoUrl" />
          </td>
        </tr>
        <tr>
          <td class="meta">
            <div>
              <span class="lbl" v-translation="{ code: 'label_quote_date', type: 'label' }"></span>
              <span>{{ currentDate }}</span>
            </div>
            <div>
              <span class="lbl" v-translation="{ code: 'label_quote_loc', type: 'label' }"></span>              
              <span>www.vlint.nl</span>
            </div>
          </td>
          <td></td>
        </tr>
      </table>
    </div>
  	
    <!-- Show if printing -->
    <template v-if="showPrintTables">
      <template v-if="projectOrderLines.locations.length">
        <table class="print-table print avoid-break-print">
          <thead></thead>
            <tbody>

                <tr class="project-header">
                  <td>
                    <p v-translation="{ code: 'label_projects', type: 'label'}" class="row-hdr-sm"></p>
                    <h2>{{ projectOrderLines.description }}</h2>
                  </td>
                </tr>

                <tr class="main-location-row" v-for="(location, index) in projectOrderLines.locations" :key="location.code + index">
                  <td>
                    <table class="fixed-layout">
                      <thead class="loc-hdr">
                        <th class="td-prod-img">
                          <p v-translation="{ code: 'label_location', type: 'label'}" class="row-hdr-sm"></p>
                          <h3>{{ location.description }}</h3>
                        </th>
                        <th class="td-prod-desc"></th>
                        <th class="td-prod-price"></th>        
                        <th class="td-prod-stock"></th>            
                        <th class="td-prod-qty"></th>

                        <th class="td-prod-total"></th>
                      </thead>
                      <tbody class="locations-row">
                        
                        <tr v-for="orderLine in location.orderLines" :key="orderLine.lineId" >

                          <td class="td-prod-img">
                              <img :src="orderLine.product.imageUrl" />
                          </td>

                          <td class="td-prod-desc">
                            <h3 class="prod-title body-text">{{ orderLine.product.shortDescription }}</h3>
                            <span class="prod-code-table">{{ orderLine.product.id }}</span>
                            <template>
                              <p class="comment">{{ orderLine.comments}}</p>
                            </template>

                          </td>

                          <td class="td-prod-price" v-if="orderLine.product.prices.length">
                            <utlz-product-price
                              :prices="orderLine.product.prices[0]"
                            ></utlz-product-price>
                          </td>

                          <td class="td-prod-stock">
                            <utlz-product-stock
                              :product="orderLine.product"
                              :stock="orderLine.product.stock"
                              :stockTotal="orderLine.product.stock"
                            ></utlz-product-stock>
                          </td>

                          <td class="td-prod-qty">
                            {{ orderLine.quantity }}
                          </td>

                          <td class="td-prod-total">
                            <span>{{ orderLine.totals.totalPrice }}</span>
                          </td>

                        </tr>
                      </tbody>
                    </table>
                  </td>
                    

                </tr>
            </tbody>
        </table>
      </template>
    
    <template v-if="orderLines.length">
      <table class="print-table fixed-layout print non-proj avoid-break-print">
         <thead>
          <th class="td-prod-img"></th>
          <th class="td-prod-desc"></th>
          <th class="td-prod-price"></th>       
          <th class="td-prod-stock"></th>             
          <th class="td-prod-qty"></th>
          <th class="td-prod-total"></th>
        </thead>
        <tbody>
          <tr class="default-order-lines" v-for="orderLine in orderLines" :key="orderLine.lineId">
            <td class="td-prod-img">
                <img :src="orderLine.product.imageUrl" />
            </td>

            <td class="td-prod-desc">
              <h3 class="prod-title body-text">{{ orderLine.product.shortDescription }}</h3>
              <span class="prod-code-table">{{ orderLine.product.id }}</span>
              <template>
                <p class="comment">{{ orderLine.comments}}</p>
              </template>
              
            </td>

            <td class="td-prod-price" v-if="orderLine.product.prices.length">
              <utlz-product-price
                :prices="orderLine.product.prices[0]"
              ></utlz-product-price>
            </td>

            <td class="td-prod-stock">
              <utlz-product-stock
                :product="orderLine.product"
                :stock="orderLine.product.stock"
                :stockTotal="orderLine.product.stock"
              ></utlz-product-stock>
            </td>

            <td class="td-prod-qty">
              {{ orderLine.quantity }}
            </td>

            <td class="td-prod-total">
              <span>{{ orderLine.totals.totalPrice }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    </template>
    <!-- End print -->   


    <transition name="fadein">
      <!-- BESPOKE VLINT: Changed payload to promptPayload-->
      <utlz-prompt
        v-if="showDeleteProductPrompt"
        :payload="promptPayload"
        @confirm="deleteOrderLineFromCart($event)"
        @cancel="cancelPrompt()"
      >
      <!-- END BESPOKE VLINT -->

      <template v-slot:prompt-content>
        <div class="delete-product-prompt utlz-row">
          <div class="utlz-col-12">
            <h2>
              <span v-translation="{ type: 'title', code: 'title_prompt_delete_from_cart' }"></span>
            </h2>
          </div>
          <div class="utlz-col-3 img">
            <img :src="promptPayload.product.imageUrl">
          </div>
          <div class="utlz-col-9">
            <h3>
              {{ promptPayload.product.shortDescription }}
            </h3>
          </div>
        </div>
      </template>

      </utlz-prompt>
    </transition>
    
    <!-- BESPOKE: Added prompt for the delete orderline locations -->
    
    <transition name="fadein">

      <div class="cmp-prompt" v-if="showDeleteLocationPrompt">
        <div class="full-screen-fader flex-column justify-content-center">
          <div class="utlz-container">
            <div class="utlz-row">
              <div class="utlz-col-lg-8 utlz-offset-lg-2">
                <span class="close-button">
                  <i class="fa fa-times-circle" aria-hidden="true" @click="cancelDeleteLocationPrompt()"></i>
                </span>
                <div class="prompt-content">

                  <div class="delete-location-prompt utlz-row">
                    <div class="utlz-col-12">
                      <h2>
                        <span v-translation="{ type: 'title', code: 'title_prompt_delete_from_cart' }"></span>
                      </h2>
                    </div>
                  </div>

                  <div class="action-btns loc full-width-buttons-mobile" :class="{'is-deleting': isDeletingLocation}">
                    <a @click="cancelDeleteLocationPrompt()" v-translation="{ type: 'button', code: 'button_prompt_cancel'}" class="button cancel"></a>
                    <template v-if="isDeletingLocation">
                      <span class="loader"></span>
                    </template>
                    <a @click="deleteLocation()" v-translation="{ type: 'button', code: 'button_prompt_confirm'}" class="button theme-primary confirm"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- END BESPOKE -->

    <transition name="fadein">
      <utlz-prompt
        v-if="showEmptyCartPrompt"
        @confirm="emptyCart()"
        @cancel="cancelEmptyCartPrompt()"
      >

      <template v-slot:prompt-content>
        <h2 v-translation="{ type: 'text', code: 'txt_confirm_clear_shopping' }"></h2>
      </template>

      </utlz-prompt>
    </transition>

    <transition name="fadein">
      <utlz-static-modal v-if="validatingStock">
        <template v-slot:static-modal-content>
          <div class="validate-stock-modal utlz-row">
            <div class="utlz-col-12 flex-row-nowrap align-center just-center">
              <span class="loader"></span>
              <h2>
                <span v-translation="{ type: 'label', code: 'label_validating_stock' }"></span>
              </h2>
            </div>
          </div>
        </template>
      </utlz-static-modal>
    </transition>

    <transition-group name="fade" tag="div" class="ph-wrapper utlz-col-12">
      <template v-if="loadingOrderLines">
        <div v-for="index in 4" :key="index" class="ph-silhouette orderlist-row">
          <span class="silhouette-img"></span>
          <div class="ph-paragraph">
            <span class="silhouette-line"></span>
            <span class="silhouette-line"></span>
          </div>
        </div>
      </template>
    </transition-group>

    <div v-if="shoppingCartEmpty" class="empty-cart-message col-md-12">
      <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_empty' }"></h2>
    </div>

    <!-- BESPOKE VLINT:  -->
    <div class="utlz-col-md-8 screen-only">
      <div class="row">
        <transition name="fade">
          <div v-if="showOrderLines && screenWidth >= 880" class="orderline-header utlz-col-md-12">
            <div class="orderlines-wrapper">
              <div v-if="!hideShoppingCartImages" class="prod-img orderline-cell"></div>
              <div class="prod-desc orderline-cell">
                <span v-translation="{ type: 'label', code: 'label_header_prod_desc' }"></span>
              </div>
              
              <!-- BESPOKE VLINT: Moved order product to this position -->
              <div class="order-product orderline-cell">
                <span v-translation="{ type: 'label', code: 'label_header_prod_quantity' }"></span>
              </div>
              <!-- END BESPOKE VLINT -->

              <div v-if="showPrices" class="prod-price orderline-cell">
                <span v-translation="{ type: 'label', code: 'label_header_prod_price' }"></span>
              </div>

              <!-- BESPOKE VLINT: Disabled stock in the shoppingcart -->
              <!-- <div class="prod-stock orderline-cell">
                <span v-translation="{ type: 'label', code: 'label_header_prod_stock' }"></span>
              </div> -->
              <!-- END BESPOKE VLINT -->
              
              <div v-if="showPrices" class="prod-total orderline-cell">
                <span v-translation="{ type: 'label', code: 'label_header_prod_total' }"></span>
              </div>
              <div class="delete-prod orderline-cell">
              </div>
            </div>
          </div>
        </transition>

        <!-- <transition-group name="fade" tag="div" class="orderline-table utlz-col-md-12"> -->
          <div class="orderline-table utlz-col-md-12">
          <template v-if="showOrderLines">
            <!-- BESPOKE VLINT: Deleted default rendering of orderlines, and moved it to a seperate shopping-cart-row component
              which makes it easier to render over te default orderLines and project orderLines -->            

            <template v-if="projectOrderLines.locations.length">
              <div class="project-orderlines-wrapper">
                <div class="project-header">
                  <p v-translation="{ code: 'label_projects', type: 'label'}" class="row-hdr-sm"></p>
                  <h2>{{ projectOrderLines.description }}</h2>
                </div>
                <div v-for="(location, index) in projectOrderLines.locations" :key="location.code + index" class="location-row">
                  <div class="location-header">
                    <p v-translation="{ code: 'label_location', type: 'label'}" class="row-hdr-sm"></p>

                    <div class="location-hdr">
                      <h3>{{ location.description }}</h3>
                    
                      <template v-if="location.orderLines.some(product => product.isRepresentativeProduct)">
                        <div class="delete-location" @click="promptDeleteLocation(location.code)">
                          <span class="close-button small grey"><i aria-hidden="true" class="fa fa-trash"></i></span>
                        </div>
                      </template>
                    </div>
                    

                  </div>
                  <div class="location-body">
                    <div v-for="orderLine in location.orderLines" :key="orderLine.lineId" class="orderline-wrapper">
                      <utlz-shopping-cart-row
                        :orderLine="orderLine"
                        :showPrices="showPrices"
                        :hideShoppingCartImages="hideShoppingCartImages"
                        @deleteOrderLine="promptDelete($event)"
                        @quantityChanged="quantityChanged($event, orderLine)"
                      ></utlz-shopping-cart-row>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            
            <template v-if="orderLines.length">
              <div class="default-orderlines-wrapper">
                <div v-for="orderLine in orderLines" :key="orderLine.lineId" class="orderline-wrapper default-orderlines">
                  <utlz-shopping-cart-row
                    :orderLine="orderLine"
                    :showPrices="showPrices"
                    :hideShoppingCartImages="hideShoppingCartImages"
                    @deleteOrderLine="promptDelete($event)"
                    @quantityChanged="quantityChanged($event, orderLine)"
                  ></utlz-shopping-cart-row>
                </div>
              </div>
            </template>
          </template>
          <!-- END BESPOKE VLINT -->
          </div>
        <!-- </transition-group> -->
      </div>
    </div>

    <div id="ph-addresses" class="utlz-col-md-12 print-only avoid-break-print">
    </div>

    <template v-if="showOrderLines">
      <div class="utlz-col-md-4 avoid-break-print">
        <div class="row">
          <div class="utlz-col-md-12">
            <transition name="fade">
              <utlz-shopping-cart-totals
                :format="shoppingCartTotalsFormat"
              ></utlz-shopping-cart-totals>
            </transition>
          </div>

          <div class="utlz-col-md-12 screen-only">
            <div class="print-wr">
              <h3 class="title" v-translation="{ type: 'title', code: 'title_print_quote' }"></h3>
              <div class="print-txt" v-translation="{ type: 'text', code: 'text_print_quote' }"></div>
              <button class="print-btn btn-u blue-btn screen-only" @click="print($event)">
                <i class="fa fa-print"></i>
                <span v-translation="{code: 'button_print_cart', type: 'button'}"></span>
              </button>
            </div>
          </div>

        </div>
      </div>
    </template>

    <div class="utlz-col-md-12 place-order avoid-break-print print-only">
      <h3 v-translation="{ code: 'title_place_order', type: 'title'}"></h3>
      <div v-translation="{ code: 'text_place_order', type: 'text'}" class="instr">
      </div>
    </div>

    <template v-if="showEmptyShoppingCart && !shoppingCartEmpty && !loadingOrderLines">
      <!-- BESPOKE VLINT: Changed class to utlz-col-md-8 and button classes -->
      <div class="utlz-col-md-8 clear-cart-col">
        <a @click="showEmptyCartPrompt = true" v-translation="{ type: 'button', code: 'btn_clear_shopping_cart' }" class="btn-u btn-grey"></a>
      </div>
      <!-- END BESPOKE VLINT -->
    </template>

    <div id="stock-warning" class="utlz-col-md-6">
      <transition name="fade">
        <template v-if="invalidOrderLines.length">
          <div class="stock-warning">
            <div class="stock-warning-wrapper">
              <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_stock_warning' }"></h2>
              <ul>
                <li v-for="orderLine in invalidOrderLines" :key="orderLine.lineId">
                  <i class="fa fa-caret-right" aria-hidden="true"></i><span>{{ orderLine.product.shortDescription }}</span>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </transition>

      <!-- BESPOKE VLINT-180: Removed showOrderLines, the customer always wants to be able to add a coupon code  -->
      <utlz-coupons v-if="useCoupons"></utlz-coupons>
      <!-- END BESPOKE VLINT-180 -->

    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Prompt from 'src/components/ui/prompt/Prompt.vue';
import StaticModal from 'src/components/ui/static-modal/StaticModal.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import Coupons from 'src/components/webshop/coupons/Coupons.vue';
import OrderLineComment from 'src/components/webshop/order-product/blocks/OrderLineComment.vue';
import ShoppingCartTotals from 'src/components/webshop/shopping-cart/ShoppingCartTotals.vue';
import { getProductStock  } from '../../../services/product-service';
import _ from 'lodash';

// BESPOKE VLINT
import ShoppingCartRow from './ShoppingCartRow.vue';
import Tabs from '../../../../src/components/ui/tabs/Tabs.vue';
// END BESPOKE VLINT

export default {
  components: {
    'utlz-prompt': Prompt,
    'utlz-static-modal': StaticModal,
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-coupons': Coupons,
    'utlz-order-line-comment': OrderLineComment,
    'utlz-shopping-cart-totals': ShoppingCartTotals,
    // BESPOKE VLINT
    'utlz-shopping-cart-row': ShoppingCartRow,
    Tabs
    // END BESPOKE VLINT
  },
  data () {
    return {
      loading: false,
      showDeleteProductPrompt: false,
      promptPayload: null,
      showEmptyCartPrompt: false,
      // BESPOKE
      logoUrl: "",
      showPrintTables: false,
      locationToDeleteCode: "",
      showDeleteLocationPrompt: false,
      isDeletingLocation: false
      // END BESPOKE
    }
  },
  computed: {
    ...mapGetters(['screenWidth', 'showStock', 'useCoupons', 'useOrderLineComments', 'useMinimumOrderQuantity', 'hideShoppingCartImages', 'showEmptyShoppingCart', 'showPrices']),
    // BESPOKE VLINT
    // Added projectOrderLines
    ...mapGetters('shoppingCart', [
      'orderLines', 
      'projectOrderLines',
      'invalidOrderLines', 
      'loadingOrderLines', 
      'orderLinesTotal', 
      'minimumOrderQuantity', 
      'shoppingCartEmpty', 
      'shoppingCartTotalsFormat', 
      'validatingStock']),
    // END BESPOKE VLINT
    currentDate() {
      let date = new Date();
      return date.toLocaleDateString('nl-NL');
    },
    showOrderLines () {
      // BESPOKE VLINT
      // Also take project orderlines into account
      var orderLinesRetrieved = this.orderLines.length > 0 && this.orderLines[0].product !== null;
      var projectOrderLinesRetrieved = this.projectOrderLines.locations.length > 0 && this.projectOrderLines.locations[0].orderLines[0].product !== null; 
      var showOrderLines = orderLinesRetrieved || projectOrderLinesRetrieved;
      return showOrderLines;
      // END BESPOKE VLINT
    },
    tableClasses () {
      let classes = '';
      if (!this.showStock) {
        classes += 'hide-stock ';
      }
      if (this.hideShoppingCartImages) {
        classes += 'hide-images ';
      }
      return classes;
    }
  },
  methods: {
    ...mapActions('shoppingCart', ['initShoppingCart', 'updateOrderLineQuantity', 'deleteOrderLine', 'emptyShoppingCart', 'deleteLocationOrderLines']),
    print(event) {
      event.preventDefault();
      this.showPrintTables = true;
      $('#ph-addresses').empty(); 
      $('#ph-addresses').append($('#addresses').clone()); 
      
      window.printingQuote = true;
      setTimeout(() => {window.print();}, 500);
      
      
    },
    quantityChanged ({ computedQuantity }, orderLine) {
      const payload = {
        Comments: orderLine.comments,
        ProductId: orderLine.productId,
        LineId: orderLine.lineId,
        Quantity: computedQuantity.toString(),
        UnitCode: orderLine.unitCode,
        IsFree: orderLine.isFree,
        // BESPOKE VLINT
        // Added isProjectListOrderLine
        isProjectOrderLine: orderLine.projectName != undefined
        // END BESPOKE VLINT
      };
      this.updateOrderLineQuantity(payload).then(res => {
      });
    },
    unitsChanged ({ unitCode, quantity, computedQuantity }, orderLine) {
      const payload = {
        Comments: orderLine.comments,
        ProductId: orderLine.productId,
        LineId: orderLine.lineId,
        Quantity: computedQuantity.toString(),
        UnitCode: unitCode,
        IsFree: orderLine.isFree
      };
      this.updateOrderLineQuantity(payload).then(res => {
      });
    },
    promptDelete (orderLine) {
      this.showDeleteProductPrompt = true;
      this.promptPayload = orderLine;
    },
    cancelPrompt () {
      this.showDeleteProductPrompt = false;
      this.promptPayload = null;
    },
    deleteOrderLineFromCart (orderLineId) {
      this.deleteOrderLine(orderLineId);
      this.showDeleteProductPrompt = false;
      this.promptPayload = null;
    },
    cancelEmptyCartPrompt () {
      this.showEmptyCartPrompt = false;
    },
    emptyCart () {
      this.showEmptyCartPrompt = false;
      this.emptyShoppingCart();
    },
    // BESPOKE
    promptDeleteLocation(locationCode) {
      this.locationToDeleteCode = locationCode;
      this.showDeleteLocationPrompt = true;
    },
    cancelDeleteLocationPrompt() {
      this.locationToDeleteCode = "";
      this.showDeleteLocationPrompt = false;
    },
    deleteLocation() {
      this.isDeletingLocation = true;
      this.deleteLocationOrderLines(this.locationToDeleteCode).then(_ => {
        this.isDeletingLocation = false;
        this.showDeleteLocationPrompt = false;
        this.locationToDeleteCode = "";
      });
    }
    // END BESPOKE
  },
  created () {

    window.onafterprint = function(){
      $('#ph-addresses').empty(); 
      this.showPrintTables = false;
      console.log("Printing completed...");
    }

    this.initShoppingCart();
    this.quantityChanged = _.debounce(this.quantityChanged, 500);

    // BESPOKE
    this.logoUrl = $("#uc_header_img_logo").attr('src');
    // END BESPOKE
  }
}
</script>

<style>

</style>
